import axios from 'axios';
import { getAuth } from './auth.service';

export const postWithAuth = (url, entity, token = '') => new Promise((resolve, _reject) => {
    if (!token) token = getAuth()?.token;
    const headers = {
        'content-type': 'application/json',
        'auth0-token': token,
        'x-platform': 'adminWeb'
    };
    axios
        .post(url, entity, { headers })
        .then((response) => {
            if (response && response.data) {
                resolve({ status: true, data: response.data });
            }
        })
        .catch((ex) => {
            resolve({ status: false, message: ex.response?.data?.message, error: ex.response?.data });
        });
});

export const getWithAuth = (url, token = '') => {
    if (!token) token = getAuth()?.token;
    const headers = {
        'content-type': 'application/json',
        'auth0-token': token,
        'x-platform': 'adminWeb'
    };
    return new Promise((resolve, _reject) => {
        axios
            .get(url, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve({ status: true, data: response.data });
                }
            })
            .catch((ex) => {
                resolve({ status: false, message: ex.response?.data?.message, error: ex.response?.data });
            });
    });
};

export const getWithOutAuth = (url) => {
    const headers = {
        'content-type': 'application/json',
        'x-platform': 'adminWeb'
    };
    return new Promise((resolve, _reject) => {
        axios
            .get(url, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve({ status: true, data: response.data });
                }
            })
            .catch((ex) => {
                resolve({ status: false, message: ex.response?.data?.message, error: ex.response?.data });
            });
    });
};

export const deleteWithAuth = (url, data) => {
    const auth = getAuth();

    const headers = {
        'content-type': 'application/json',
        'auth0-token': auth?.token,
        'x-platform': 'adminWeb'
    };

    return new Promise((resolve, _reject) => {
        axios
            .delete(url, { data, headers })
            .then((response) => {
                if (response && response.data) {
                    resolve({ status: true, data: response.data });
                }
            })
            .catch((ex) => {
                resolve({ status: false, message: ex.response?.data?.message, error: ex.response?.data });
            });
    });
};

export const putWithAuth = (url, data) => {
    const auth = getAuth();
    const headers = {
        'content-type': 'application/json',
        'auth0-token': auth?.token,
        'x-platform': 'adminWeb'
    };
    return new Promise((resolve, _reject) => {
        axios
            .put(url, data, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve({ status: true, data: response.data });
                }
            })
            .catch((ex) => {
                resolve({ status: false, message: ex.response?.data?.message, error: ex.response?.data });
            });
    });
};

export const patchWithAuth = (url, data) => {
    const auth = getAuth();
    const headers = {
        'content-type': 'application/json',
        'auth0-token': auth?.token,
        'x-platform': 'adminWeb'
    };
    return new Promise((resolve, _reject) => {
        axios
            .patch(url, data, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve({ status: true, data: response.data });
                }
            })
            .catch((ex) => {
                resolve({ status: false, message: ex.response?.data?.message, error: ex.response?.data });
            });
    });
};

export const postWithOutAuth = (url, entity) => new Promise((resolve, _reject) => {
    const headers = {
        'content-type': 'application/json',
        'x-platform': 'adminWeb'
    };
    axios
        .post(url, entity, { headers })
        .then((response) => {
            if (response && response.data) {
                resolve({ status: true, data: response.data });
            }
        })
        .catch((ex) => {
            resolve({ status: false, message: ex.response?.data?.message, error: ex.response?.data });
        });
});
