import { useUser } from '@auth0/nextjs-auth0';
import Loader from 'atoms/Loader';
import { getAuth } from 'services/auth.service';

const isBrowser = () => typeof window !== 'undefined';

const WithAuth = ({ router, children }) => {
    const { user, isLoading } = useUser();

    const unProtectedRoutes = ['/', '/error', '/termandcondition', '/product', '/regulatoryInfo/DeonRuleBook', '/liveprices', '/liveprices/macro', '/liveprices/bonds'];

    const isProtected = !unProtectedRoutes.includes(router.pathname);

    if (isLoading) {
        return <Loader />;
    }

    if (isBrowser() && isProtected && (!user || !getAuth())) {
        router.replace('/');
        return null;
    }
    return children;
};

export default WithAuth;
