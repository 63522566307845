const ApiUrl = process.env.NEXT_PUBLIC_API_URL;

const UrlParamsReplace = (url, params = {}) => {
    let urlWithPrefix = `${ApiUrl}${url}`;
    if (params) {
        Object.keys(params).forEach((key) => (urlWithPrefix = urlWithPrefix.replace(`:${key}`, params[key])));
    }
    return urlWithPrefix;
};

export const CREATE_CONTACT = UrlParamsReplace('/contacts');

export const USER_LOGIN_URL = UrlParamsReplace('/login');
export const CREATE_COMPANY = () => UrlParamsReplace('/companies');
export const GET_ALL_COMPANIES = ({ offset, limit, role }) => UrlParamsReplace(
    '/companies?offset=:offset&limit=:limit&role=:role',
    { offset, limit, role }
);

export const GET_UPLOAD_PRE_SIGNED_URL = UrlParamsReplace('/aws/get-upload-pre-signed-url');
export const CREATE_BOND_URL = UrlParamsReplace('/bonds');
export const CREATE_USER_URL = UrlParamsReplace('/user');
export const UPDATE_USER_URL = (id) => UrlParamsReplace('/user/:id', { id });
export const CREATE_BOND_PRICE_HISTORY_URL = UrlParamsReplace('/bond-price-history');
export const RESEND_WELCOME_EMAIL = (id) => UrlParamsReplace('/resend-welcome-email/:id', { id });

export const UPDATE_COMPANY_URL = (id) => UrlParamsReplace('/companies/:id', { id });
export const GET_PAGINATED_USER_URL = (
    offset,
    limit,
    role,
    phrase
) => UrlParamsReplace('/users?offset=:offset&limit=:limit&role=:role&phrase=:phrase', {
    offset,
    limit,
    role,
    phrase
});
export const GET_PAGINATED_USER_FOR_COMPLIANCE_URL = (
    offset,
    limit,
    phrase
) => UrlParamsReplace('/compliance/users?offset=:offset&limit=:limit&phrase=:phrase', { offset, limit, phrase });
export const GET_CHAT_TOKEN_FOR_COMPLIANCE_URL = (userId) => UrlParamsReplace('/compliance/auth?userId=:userId', { userId });
export const GET_HOOTS_FOR_COMPLIANCE_URL = () => UrlParamsReplace('/compliance/hoot');
export const GET_HOOT_SEARCH_USER_FOR_COMPLIANCE_URL = ({
    phrase,
    sameCompany
}) => UrlParamsReplace('/compliance/users/search?phrase=:phrase&sameCompany=:sameCompany', { phrase, sameCompany });
export const GET_HOOT_SIGNED_BY_ID_URL = ({ id }) => UrlParamsReplace('/compliance/hoot/signed/:id', { id });

export const CREATE_SECTOR_URL = () => UrlParamsReplace('/sectors');
export const UPDATE_SECTOR_URL = (id) => UrlParamsReplace('/sectors/:id', { id });
export const GET_ALL_SECTORS_URL = (offset, limit) => UrlParamsReplace('/sectors?offset=:offset&limit=:limit', { offset, limit });
export const UPDATE_SECTOR_STATUS_URL = (id, status) => UrlParamsReplace('/sectors-status/:id?status=:status', { id, status });
export const GET_ALL_SECTORS_FOR_USER_URL = () => UrlParamsReplace('/sectors/all');

export const CREATE_BOND_LIVES_URL = UrlParamsReplace('/bond-lives');
export const CREATE_OR_UPDATE_BONDS_URL = UrlParamsReplace('/create-bonds');
export const GET_SECTORS_URL = UrlParamsReplace('/sectors');
export const UPDATE_USER_SECTORS = UrlParamsReplace('/users-sectors');

export const GET_PUSH_NOTIFICATIONS_URL = () => UrlParamsReplace('/push-notification');
export const GET_BOND_ACTIONS_URL = ({
    offset, limit, type, search
}) => UrlParamsReplace('/bond-actions?offset=:offset&limit=:limit&type=:type&search=:search', {
    offset,
    limit,
    type,
    search
});
export const GET_TRADER_COMPANIES_URL = UrlParamsReplace('/trader-company');
export const PATCH_TRADER_COMPANIES_URL = UrlParamsReplace('/trader-company');
export const GET_COMPANIES_BROKERS = UrlParamsReplace('/trader-company/brokers');
export const DELETE_SECTOR_URL = (id) => UrlParamsReplace('/sectors/:id', { id });
export const GET_BONDS_BY_ISIN_URL = () => UrlParamsReplace('/get-bonds-by-isins');
// export const GET_SECTORS_WITH_BONDS_URL = ({ sectorIds }) => UrlParamsReplace('/sectors-bonds?sectorIds=:sectorIds', { sectorIds });
export const GET_SECTORS_WITH_BONDS_URL = UrlParamsReplace('/sectors-bonds');

export const CREATE_BOND_SECTOR_URL = () => UrlParamsReplace('/bond-sectors');
export const GET_MATCHING_SETTINGS_URL = () => UrlParamsReplace('/matching-settings');
export const UPDATE_MATCHING_SETTINGS_URL = () => UrlParamsReplace('/matching-settings');

export const GET_USER_DETAILS_URL = () => UrlParamsReplace('/user/details');

export const SEARCH_SECTORS_URL = ({ offset, limit, phrase }) => UrlParamsReplace(
    '/sectors/search?offset=:offset&limit=:limit&phrase=:phrase',
    { offset, limit, phrase }
);
export const DELETE_BONDS_URL = UrlParamsReplace('/bonds');
export const BOND_YAS_CALCULATION_URL = (isin) => UrlParamsReplace('/bonds/yas/:isin', { isin });
export const CREATE_INDEX_HISTORY_URL = UrlParamsReplace('/indexes-histories');
export const GET_INDEX_HISTORIES = (indexId) => UrlParamsReplace('/indexes-histories/:id', { id: indexId });
export const GET_HS_BOND_INDEX_HISTORIES = ({ bondId, indexName, type }) => UrlParamsReplace(
    `/indexes-histories/bonds/:bondId/HS?${indexName ? 'indexName=:indexName' : ''}${type ? '&type=:type' : ''}`,
    { bondId, indexName, type }
);
export const GET_BASIS_BOND_INDEX_HISTORIES = ({ bondId, indexName, type }) => UrlParamsReplace(
    `/indexes-histories/bonds/:bondId/BASIS?${indexName ? 'indexName=:indexName' : ''}${type ? '&type=:type' : ''}`,
    { bondId, indexName, type }
);

export const GET_TOP_NEWS_URL = (offset, limit) => UrlParamsReplace('/top-news?offset=:offset&limit=:limit', { offset, limit });
export const CREATE_INDEX_SECURITY_URL = UrlParamsReplace('/indexes-securities');
export const CREATE_INDEX_LIVE_URL = UrlParamsReplace('/indexes-lives');

export const GET_COMPANY_NEWS_URL = (page, limit, phrase) => UrlParamsReplace(
    '/company-news?page=:page&limit=:limit&phrase=:phrase',
    { page, limit, phrase }
);

export const EDIT_USER_PROFILE_URL = (id) => UrlParamsReplace('/user/profile/:id', { id });

export const CREATE_HOLIDAY = UrlParamsReplace('/holidays');
export const UPDATE_HOLIDAY = (id) => UrlParamsReplace(`/holidays/${id}`);
export const DELETE_HOLIDAY = (id) => UrlParamsReplace(`/holidays/${id}`);
export const GET_HOLIDAYS = UrlParamsReplace('/holidays');
export const GET_CURRENCIES = UrlParamsReplace('/currencies');
export const GET_CDRS = UrlParamsReplace('/cdrs');
export const CREATE_IRS_HISTORY_URL = UrlParamsReplace('/irs-histories');
export const GET_IRS_HISTORY = (id) => UrlParamsReplace('/irs-histories/:id', { id });
export const GET_CURRENCY_HISTORY = (id) => UrlParamsReplace('/currency-histories/:id', { id });
export const UPDATE_USER_STATUS_URL = (id) => UrlParamsReplace('/users/:id', { id });
export const GET_HOOT_TOKEN_URL = UrlParamsReplace('/hoot/token');
export const SESSION_LOGOUT = UrlParamsReplace('/sessions/disconnect');
export const DELETE_BONDS_PRICE_HISTORY_URL = UrlParamsReplace('/bonds/price-histories');
export const CREATE_OR_UPDATE_USER_BOND = UrlParamsReplace('/users-bonds');
export const GET_BONDS_PRICE_HISTORY_URL = (id, limit) => UrlParamsReplace(`/bonds/:id/price-histories?${limit ? `limit=${limit}` : ''}`, { id });
export const PATCH_BOND_ID = (id) => UrlParamsReplace('/bonds/:id', { id });
export const PATCH_BOND_LEVELS = UrlParamsReplace('/bonds/bond-level');

export const GET_USER_SECTOR_BONDS = UrlParamsReplace('/user-sectors');
