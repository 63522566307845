import PropTypes from 'prop-types';
import { X } from 'react-feather';

const InputBox = ({
    isError,
    errorMessage,
    className,
    variant,
    disabled,
    type,
    register,
    id,
    dbName,
    isLabel,
    labelFor,
    labelText,
    labelClass,
    placeholder,
    iconComponent,
    reference,
    bgColor,
    iconClass,
    inputRadius,
    inputBorder,
    errorClass,
    isCloseIcon = false,
    onClearSearch,
    placeholderColor,
    closeIconClass,
    autoFocus = false,
    ...otherProps
}) => {
    const inputDisabledClass = disabled
        ? 'cursor-not-allowed disabled:bg-gray-100 disabled:border-gray-300 disabled:placeholder:text-gray-500'
        : '';

    const inputBorderStyle = isError
        ? 'border-error-300 hover:border-error-300 focus:border-error-300'
        : `${inputBorder || 'border-transparent hover:border-primary-300 focus:border-primary-300'}`;

    return (
        <div className="relative">
            {isLabel && (
                <label
                    htmlFor={labelFor || id}
                    className={`text-neutral-900 text-base leading-6 font-normal block pb-2 ${labelClass}`}
                >
                    {labelText}
                </label>
            )}

            <div className={'relative flex items-center'}>
                {iconComponent && (
                    <div className={`${iconClass} absolute left-3 text-gray-200`}>
                        {iconComponent}
                    </div>
                )}
                <input autoComplete="off"
                    autoFocus={autoFocus}
                    id={id}
                    type={type || 'text'}
                    placeholder={placeholder}
                    ref={reference}
                    {...(register && { ...register(dbName) })}
                    className={`
                    ${iconComponent ? 'pl-10' : 'pl-4'} border pr-4 h-10 w-full outline-none font-normal ${placeholderColor || 'placeholder:text-gray-200'} text-gray-100 appearance-none select-none
                    ${inputDisabledClass}
                    ${bgColor || 'bg-gray-500'}
                    ${inputRadius || 'rounded-md'}
                    ${inputBorderStyle}
                    ${className}
          `}
                    disabled={disabled}
                    {...otherProps}
                    aria-invalid={isError}
                />
                {isCloseIcon && <X size={16} className={`text-basic-200 absolute right-3.5 stroke-[3px] ${closeIconClass}`} onClick={onClearSearch} />}
                {isError && (
                    <span
                        className={`text-error-300 pl-0.5 text-sm pt-2 font-normal leading-[21px] select-none ${errorClass}`}
                        role="alert"
                    >
                        {errorMessage}
                    </span>
                )}
            </div>
        </div>
    );
};

InputBox.defaultProps = {
    isError: false,
    className: '',
    variant: 'Default',
    disabled: false,
    isLabel: false,
    iconComponent: null,
    id: '',
    bgColor: ''
};

InputBox.propTypes = {
    id: PropTypes.string,
    dbName: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    disableClass: PropTypes.string,
    baseClasses: PropTypes.string,
    className: PropTypes.string,
    variant: PropTypes.oneOf(['Default', 'Small', 'Large']),
    disabled: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
    register: PropTypes.func,
    isLabel: PropTypes.bool,
    labelText: PropTypes.string,
    labelFor: PropTypes.string,
    labelClass: PropTypes.string,
    iconComponent: PropTypes.node,
    bgColor: PropTypes.string,
    iconClass: PropTypes.string
};

export default InputBox;
