import useClickOutside from '@/components/common/ClickOutSide';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { MoreHorizontal } from 'react-feather';

const ActionList = ({
    options, data, handleSelect, children, className
}) => {
    const [show, setShow] = useState(false);

    const handleClick = () => {
        setShow(true);
        handleSelect(data);
    };
    const ref = useRef();
    useClickOutside(ref, () => { setShow(false); });
    return (
        <div className={`${className} relative text-center `}>
            {children ? <div onClick={handleClick}>{children}</div>
                : <button className="more-options-btn text-center" onClick={handleClick}>
                    <MoreHorizontal size={22} />
                </button>
            }

            {show && <div
                className={'bg-gray-600 w-max z-10 rounded-md right-0 overflow-hidden absolute mt-1.5'}
                ref={ref}>
                {
                    options.map((item) => <div
                        key={item.id}
                        onClick={() => {
                            item.onClick(data);
                            setShow(false);
                        }}
                        className={`flex items-center gap-2 px-4 py-2 text-left text-gray-200 hover:text-gray-100 
                        text-sm hover:bg-gray-500 cursor-pointer transition-all`}
                    >
                        {item?.icon && item.icon}
                        {item.label}
                    </div>)
                }
            </div>}
        </div>
    );
};

ActionList.defaultProps = {
    handleSelect: () => { },
    children: '',
    options: []
};

ActionList.propTypes = {
    options: PropTypes.arrayOf(PropTypes.object),
    children: PropTypes.node,
    handleSelect: PropTypes.func
};

export default ActionList;
